// 🏠 STATE

// State Key
export const IN_PRODUCT_ONBOARDING_SHOW_KEY = "inProductOnboardingShow"

// State Value Type
export type InProductOnboardingShowType = boolean

// State Value (Initial)
export const inProductOnboardingShowInitialState: InProductOnboardingShowType =
  false
