import { applyMiddleware, createStore } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import thunkMiddleware from "redux-thunk"

import { rootReducer, StateType } from "@redux/ducks"
import monitorReducerEnhancer from "@redux/enhancers/monitorReducer"
import loggerMiddleware from "@redux/middlewares/logger"

export const configureStore = (initialState: StateType) => {
  // Middleware adds extra functionality to the Redux dispatch function
  // Spreading an array, means applying the array length number of arguments
  const middlewares = [loggerMiddleware, thunkMiddleware]
  const middlewareEnhancer = applyMiddleware(...middlewares)

  // Enhancers add extra functionality to the Redux store.
  const enhancers = [middlewareEnhancer, monitorReducerEnhancer]
  const composedEnhancers = composeWithDevTools(...enhancers)

  const store = createStore(rootReducer, initialState, composedEnhancers)

  return store
}
