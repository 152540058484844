// These constant values have to be synced with hair-colours.scss

export const HAIR_COLOUR_BROWN_BANDIT = "avatarHCBrownBandit"

export const HAIR_COLOUR_BEIGE_DRUID = "avatarHCBeigeDruid"

export const HAIR_COLOUR_ORANGE_DWARF = "avatarHCOrangeDwarf"

export const HAIR_COLOUR_GREEN_SHOULDER_LENGTH_WAVY =
  "avatarHCGreenShoulderLengthWavy"

export const HAIR_COLOUR_PURPLE_FRINGE_STRAIGHT_LONG =
  "avatarHCPurpleFringeStraightLong"

export const HAIR_COLOUR_YELLOW_PARTED_FRONT_PONYTAIL =
  "avatarHCYellowPartedFrontPonytail"

export type HairColourId =
  | typeof HAIR_COLOUR_BROWN_BANDIT
  | typeof HAIR_COLOUR_BEIGE_DRUID
  | typeof HAIR_COLOUR_ORANGE_DWARF
  | typeof HAIR_COLOUR_GREEN_SHOULDER_LENGTH_WAVY
  | typeof HAIR_COLOUR_PURPLE_FRINGE_STRAIGHT_LONG
  | typeof HAIR_COLOUR_YELLOW_PARTED_FRONT_PONYTAIL
