// 🏠 STATE

// State Key
export const SOLUTION_SHOW_KEY = "solutionShow"

// State Value Type
export type SolutionShowType = boolean

// State Value (Initial)
export const solutionShowInitialState: SolutionShowType = false
