// 🏠 STATE

// State Key
export const STARS_TANK_KEY = `starsTank`

// State Value Type
export type StarsTankType = number

// State Value (Initial)
export const starsTankInitialState: StarsTankType = 0
