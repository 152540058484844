import { ProblemV2Type } from "@views/multiple-choice-problem/types"

// 🏠 STATE

// State Key
export const PROBLEM_KEY = "problem"

// State Value Type
export type ProblemOptionalType = ProblemV2Type | undefined

// State Value (Initial)
export const problemInitialState: ProblemOptionalType = undefined
