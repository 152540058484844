// 🏠 STATE

// State Key
export const OPTIONS_ARE_LOCKED_KEY = "optionsAreLocked"

// State Value Type
export type OptionsAreLockedType = boolean

// State Value (Initial)
export const optionsAreLockedInitialState: OptionsAreLockedType = false
