// Intro:
// User Synced state we consider to be persistable between problems and views AND synced to the remote.
// For physics, this is under a unique id key, and then in the pentagon item attribute.
import {
  AVATAR_TANK_KEY,
  AvatarTankType,
  avatarTankInitialState,
  SET_AVATAR_TANK,
  SetAvatarTankActionType,
} from "@redux/ducks/user-synced/avatar-tank"
import {
  HAS_SEEN_PHYSICS_ONBOARDING_KEY,
  HasSeenpPhysicsOnboardingType,
  hasSeenPhysicsOnboardingInitialState,
} from "@redux/ducks/user-synced/has-seen-physics-onboarding"
import {
  POTIONS_TANK_KEY,
  PotionsTankType,
  potionsTankInitialState,
} from "@redux/ducks/user-synced/potions-tank"
import {
  STARS_TANK_KEY,
  StarsTankType,
  starsTankInitialState,
} from "@redux/ducks/user-synced/stars-tank"
import {
  USER_STATUS_KEY,
  UserStatusType,
  userStatusInitialState,
} from "@redux/ducks/user-synced/user-status"

import { META_STATUS_NEUTRAL } from "@helpers/hooks/use-meta-status/constants"
import { MetaStatusValueType } from "@helpers/hooks/use-meta-status/types"

export { selectUserSynced } from "./selectors"

// 🏠 STATE

// State Key
export const USER_SYNCED_KEY = "userSynced"

// State Value Type
export interface UserSyncedType {
  metaStatusValue: MetaStatusValueType
  [AVATAR_TANK_KEY]: AvatarTankType
  [POTIONS_TANK_KEY]: PotionsTankType
  [STARS_TANK_KEY]: StarsTankType
  [HAS_SEEN_PHYSICS_ONBOARDING_KEY]: HasSeenpPhysicsOnboardingType
  [USER_STATUS_KEY]: UserStatusType
}

// Payload Type. Payload is different from the state of the slice.
// (In most cases) everything should be optional so that a shallow merge is performed on state update.
interface UserSyncedPayloadType {
  metaStatusValue?: MetaStatusValueType
  [AVATAR_TANK_KEY]?: AvatarTankType
  [POTIONS_TANK_KEY]?: PotionsTankType
  [STARS_TANK_KEY]?: StarsTankType
  [HAS_SEEN_PHYSICS_ONBOARDING_KEY]?: HasSeenpPhysicsOnboardingType
  [USER_STATUS_KEY]?: UserStatusType
}

// State Value (Initial)
export const userSyncedInitialState: UserSyncedType = {
  metaStatusValue: META_STATUS_NEUTRAL,
  [AVATAR_TANK_KEY]: avatarTankInitialState,
  [POTIONS_TANK_KEY]: potionsTankInitialState,
  [STARS_TANK_KEY]: starsTankInitialState,
  [HAS_SEEN_PHYSICS_ONBOARDING_KEY]: hasSeenPhysicsOnboardingInitialState,
  [USER_STATUS_KEY]: userStatusInitialState,
}

// ⚡ ACTIONS

// 1. Set User Synced
// 1a. Action Constant
const SET_USER_SYNCED = "SET_USER_SYNCED"

// 1b. Action Type
interface SetUserSyncedActionType {
  type: typeof SET_USER_SYNCED
  payload: UserSyncedPayloadType
}

// 1c. Action creator
export const setUserSyncedActionCreator = (
  payload: UserSyncedPayloadType
): SetUserSyncedActionType => {
  return {
    type: SET_USER_SYNCED,
    payload,
  }
}

// Action Types (All): All possible actions
export type UserSyncedActionType =
  | SetUserSyncedActionType
  | SetAvatarTankActionType

// 🔻 Reducer
export const userSyncedReducer = (
  state: UserSyncedType = userSyncedInitialState,
  action: UserSyncedActionType
): UserSyncedType => {
  switch (action.type) {
    case SET_USER_SYNCED:
      return { ...state, ...action.payload }
    case SET_AVATAR_TANK:
      return {
        ...state,
        [AVATAR_TANK_KEY]: { ...state[AVATAR_TANK_KEY], ...action.payload },
      }
    default:
      return state
  }
}
