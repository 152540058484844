// These constant values have to be synced with skin-tones.scss

export const SKIN_TONE_1 = "avatarSkinTone1"
export const SKIN_TONE_2 = "avatarSkinTone2"
export const SKIN_TONE_3 = "avatarSkinTone3"
export const SKIN_TONE_4 = "avatarSkinTone4"
export const SKIN_TONE_5 = "avatarSkinTone5"

export type SkinToneId =
  | typeof SKIN_TONE_1
  | typeof SKIN_TONE_2
  | typeof SKIN_TONE_3
  | typeof SKIN_TONE_4
  | typeof SKIN_TONE_5
