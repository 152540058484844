export const KNIGHT_WEAPON = "KnightWeapon"
export const CRYSTAL_WIZARD_WEAPON = "CrystalWizardWeapon"
export const BANDIT_WEAPON = "BanditWeapon"
export const SCHOLAR_WEAPON = "ScholarWeapon"

export type WeaponId =
  | typeof KNIGHT_WEAPON
  | typeof CRYSTAL_WIZARD_WEAPON
  | typeof BANDIT_WEAPON
  | typeof SCHOLAR_WEAPON
