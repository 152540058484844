// This only matters for free accounts.
// 🏠 STATE

// State Key
export const PHYSICS_QUESTIONS_ANSWERED_COUNT_KEY =
  "physicsQuestionsAnsweredCount"

// State Value Type
export type PhysicsQuestionsAnsweredCountType = number

// State Value (Initial)
export const physicsQuestionsAnsweredCountInitialState: PhysicsQuestionsAnsweredCountType = 0
