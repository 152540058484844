const round = (number: number) => Math.round(number * 100) / 100

const monitorReducerEnhancer = (createStore: any) => (
  reducer: any,
  initialState: any,
  enhancer: any
) => {
  const monitoredReducer = (state: any, action: any) => {
    let diff = 0
    let newState
    if (typeof performance !== "undefined") {
      const start = performance.now()
      newState = reducer(state, action)
      const end = performance.now()
      diff = round(end - start)
    }

    console.log("reducer process time:", diff)

    return newState
  }

  return createStore(monitoredReducer, initialState, enhancer)
}

export default monitorReducerEnhancer
