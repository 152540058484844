/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:e8519eb0-07f9-497d-b64a-819df26ba578",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_6AyPPciBM",
    "aws_user_pools_web_client_id": "284slcgukff9cbph9bsfucd1ol",
    "oauth": {
        "domain": "educats-sign-in-dev.auth.ap-southeast-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.educats.com.au/app/,http://localhost:9000/app/",
        "redirectSignOut": "https://www.educats.com.au/sign-out/,http://localhost:9000/sign-out/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://cvvxsbvn2jhjbhy7h2j4cytcl4.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_mobile_analytics_app_id": "ec3ecfe42ed649578a12db4cc2941834",
    "aws_mobile_analytics_app_region": "us-west-2"
};


export default awsmobile;
