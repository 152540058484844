import { PaymentDataType } from "./index"

export const getIsPaymentValid = (
  data: PaymentDataType | undefined
): boolean => {
  if (data === undefined) {
    return false
  }

  const dateNow = new Date()

  // JS counts from 0 for month
  const dateFrom = new Date(
    data.fromDate.year,
    data.fromDate.month - 1,
    data.fromDate.day
  )

  const dateTo = new Date(
    data.toDate.year,
    data.toDate.month - 1,
    data.toDate.day
  )

  if (dateNow > dateFrom && dateNow < dateTo) {
    return true
  }

  return false
}
