import { KNIGHT_BODY, BodyId } from "@components/Avatar/constants/body"
import { NEUTRAL_EDGE_FACE, FaceId } from "@components/Avatar/constants/face"
import {
  HAIR_COLOUR_BROWN_BANDIT,
  HairColourId,
} from "@components/Avatar/constants/hair-colour"
import {
  DRUID_HAIR_STYLE,
  HairStyleId,
} from "@components/Avatar/constants/hair-style"
import { HEAD_1, HeadId } from "@components/Avatar/constants/head"
import { SKIN_TONE_3 } from "@components/Avatar/constants/skin-tone"
import { SkinToneId } from "@components/Avatar/constants/skin-tone"
import { TANK_STANCE_ID } from "@components/Avatar/constants/stance"
import { StanceId } from "@components/Avatar/constants/stance"
import { KNIGHT_WEAPON, WeaponId } from "@components/Avatar/constants/weapon"

// 🏠 STATE
// State Key
export const AVATAR_TANK_KEY = "avatarTank"
export const HAIR_ID_KEY = "hairId"

// State Value Type
export interface AvatarTankType {
  customised: boolean
  skinTone: SkinToneId
  hairColour: HairColourId
  stanceId: StanceId
  [HAIR_ID_KEY]: HairStyleId
  headId: HeadId
  faceId: FaceId
  bodyId: BodyId
  weaponId: WeaponId
}

interface AvatarTankPayloadType {
  customised?: boolean
  skinTone?: SkinToneId
  hairColour?: HairColourId
  stanceId?: StanceId
  [HAIR_ID_KEY]?: HairStyleId
  headId?: HeadId
  faceId?: FaceId
  bodyId?: BodyId
  weaponId?: WeaponId
}

// State Value (Initial)
export const avatarTankInitialState: AvatarTankType = {
  customised: false,
  skinTone: SKIN_TONE_3,
  hairColour: HAIR_COLOUR_BROWN_BANDIT,
  stanceId: TANK_STANCE_ID,
  [HAIR_ID_KEY]: DRUID_HAIR_STYLE,
  headId: HEAD_1,
  faceId: NEUTRAL_EDGE_FACE,
  bodyId: KNIGHT_BODY,
  weaponId: KNIGHT_WEAPON,
}

// ⚡ ACTIONS

// 1. Update the avatar
// 1a. Action Constant
export const SET_AVATAR_TANK = "SET_AVATAR_TANK"

// 1b. Action Type
export interface SetAvatarTankActionType {
  type: typeof SET_AVATAR_TANK
  payload: AvatarTankPayloadType
}

// 1c. Action creator
export const setAvatarTankActionCreator = (
  payload: AvatarTankPayloadType
): SetAvatarTankActionType => {
  return {
    type: SET_AVATAR_TANK,
    payload,
  }
}
