// Intro:
// Misc state we consider to be persistable between problems and views. It is a sort of uncategorised series of state we don't consider to be ephemeral like ui state. It is not an attribute of the user that's synced either.
import {
  PAYMENT_KEY,
  PaymentType,
  paymentInitialState,
} from "@redux/ducks/misc/payment"
import {
  PHYSICS_MC_USER_RESPONSES_KEY,
  PhysicsMCUserResponsesType,
  physicsMCUserResponsesInitialState,
} from "@redux/ducks/misc/physics-mc-user-responses"
import {
  PHYSICS_QUESTIONS_ANSWERED_COUNT_KEY,
  PhysicsQuestionsAnsweredCountType,
  physicsQuestionsAnsweredCountInitialState,
} from "@redux/ducks/misc/physics-questions-answered-count"
import {
  PROBLEM_KEY,
  ProblemOptionalType,
  problemInitialState,
} from "@redux/ducks/misc/problem"
import { USER_KEY, UserType, userInitialState } from "@redux/ducks/misc/user"

import { MonsterClassType } from "@helpers/constants/monster-class"

// 🏠 STATE

// State Key
export const MISC_KEY = "misc"

// State Value Type
export interface MiscType {
  dungeonMonster: MonsterClassType
  [USER_KEY]: UserType
  [PROBLEM_KEY]: ProblemOptionalType
  [PHYSICS_MC_USER_RESPONSES_KEY]: PhysicsMCUserResponsesType
  [PHYSICS_QUESTIONS_ANSWERED_COUNT_KEY]: PhysicsQuestionsAnsweredCountType
  [PAYMENT_KEY]: PaymentType
}

// Payload Type. Payload is different from the state of the slice.
// (In most cases) everything should be optional so that a shallow merge is performed on state update.
interface MiscPayloadType {
  dungeonMonster?: MonsterClassType
  [USER_KEY]?: UserType
  [PROBLEM_KEY]?: ProblemOptionalType
  [PHYSICS_MC_USER_RESPONSES_KEY]?: PhysicsMCUserResponsesType
  [PHYSICS_QUESTIONS_ANSWERED_COUNT_KEY]?: PhysicsQuestionsAnsweredCountType
  [PAYMENT_KEY]?: PaymentType
}

// State Value (Initial)
export const miscInitialState: MiscType = {
  dungeonMonster: "skeleton",
  [USER_KEY]: userInitialState,
  [PROBLEM_KEY]: problemInitialState,
  [PHYSICS_MC_USER_RESPONSES_KEY]: physicsMCUserResponsesInitialState,
  [PHYSICS_QUESTIONS_ANSWERED_COUNT_KEY]:
    physicsQuestionsAnsweredCountInitialState,
  [PAYMENT_KEY]: paymentInitialState,
}

// ⚡ ACTIONS

// 1. Set Misc
// 1a. Action Constant
const SET_MISC = "SET_MISC"

// 1b. Action Type
interface SetMiscActionType {
  type: typeof SET_MISC
  payload: MiscPayloadType
}

// 1c. Action creator
export const setMiscActionCreator = (
  payload: MiscPayloadType
): SetMiscActionType => {
  return {
    type: SET_MISC,
    payload,
  }
}

// Action Types (All): All possible actions
export type MiscActionType = SetMiscActionType

// 🔻 Reducer
export const miscReducer = (
  state: MiscType = miscInitialState,
  action: MiscActionType
): MiscType => {
  switch (action.type) {
    case SET_MISC:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
