// 🏠 STATE

// State Key
export const HAS_SEEN_PHYSICS_ONBOARDING_KEY = `hasSeenPhysicsOnboarding`

// State Value Type
export type HasSeenpPhysicsOnboardingType = boolean

// State Value (Initial)
export const hasSeenPhysicsOnboardingInitialState: HasSeenpPhysicsOnboardingType =
  false
