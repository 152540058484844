export const KNIGHT_BODY = "KnightBody"
export const CRYSTAL_WIZARD_BODY = "CrystalWizardBody"
export const NINJA_BODY = "NinjaBody"
export const SCHOLAR_BODY = "ScholarBody"

export type BodyId =
  | typeof KNIGHT_BODY
  | typeof CRYSTAL_WIZARD_BODY
  | typeof NINJA_BODY
  | typeof SCHOLAR_BODY
