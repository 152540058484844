// 🏠 STATE

// State Key
export const USER_KEY = "user"

// State Value Type
export type UserType = object | null

// State Value (Initial)
export const userInitialState: UserType = null
