import { META_STATUS_NEUTRAL } from "@helpers/hooks/use-meta-status/constants"
import { MetaStatusType } from "@helpers/hooks/use-meta-status/types"

import { PhysicsMCUserResponsesDataType } from "./types"

export { selectPhysicsMCUserResponses } from "./selectors"
export { PhysicsMCUserResponsesDataType } from "./types"
// 🏠 STATE

// State Key
export const PHYSICS_MC_USER_RESPONSES_KEY = "physicsMCUserResponses"

// State Value Type
export interface PhysicsMCUserResponsesType extends MetaStatusType {
  data: PhysicsMCUserResponsesDataType
}

// State Value (Initial).
export const physicsMCUserResponsesInitialState: PhysicsMCUserResponsesType = {
  data: {
    responses: {},
  },
  status: META_STATUS_NEUTRAL,
}
