import { Placement } from "@components/Avatar/types"

import { cloneObject } from "@helpers/objects"

export const TANK_STANCE_ID = "TANK_STANCE"
export const SCOUT_STANCE_ID = "SCOUT_STANCE"

export type StanceId = typeof TANK_STANCE_ID | typeof SCOUT_STANCE_ID

export interface StanceType {
  hair: Placement
  face: Placement
  neck: Placement
  weapon: Placement
  rightHand: Placement
}

const commonHair = {
  position: {
    left: 112,
    top: 39,
  },
}

const commonFace = {
  position: {
    left: 153,
    top: 147,
  },
}

const commonNeck = {
  position: {
    left: 133,
    top: 213,
  },
}

// left / right hand is from the characters perspective
export const TANK_STANCE: StanceType = {
  hair: commonHair,
  face: commonFace,
  neck: commonNeck,
  weapon: {
    position: {
      left: 198,
      top: 262,
    },
    rotation: 40,
  },
  rightHand: {
    position: { left: 57, top: 273 },
  },
}

export const MAKER_STANCE: StanceType = {
  hair: commonHair,
  face: commonFace,
  neck: commonNeck,
  weapon: {
    position: {
      left: 170,
      top: 270,
    },
    rotation: 60,
  },
  rightHand: {
    position: { left: 70, top: 273 },
  },
}

export const EMPATH_STANCE: StanceType = {
  hair: commonHair,
  face: commonFace,
  neck: commonNeck,
  weapon: {
    position: {
      left: 195,
      top: 255,
    },
    rotation: 40,
  },
  rightHand: {
    position: { left: 40, top: 273 },
  },
}

export const SCOUT_STANCE: StanceType = {
  hair: commonHair,
  face: commonFace,
  neck: commonNeck,
  weapon: {
    position: {
      left: 187,
      top: 280,
    },
    rotation: 61,
  },
  rightHand: {
    position: { left: 48, top: 275 },
  },
}
