import { combineReducers } from "redux"

import { MISC_KEY,miscReducer, miscInitialState, MiscType } from "./misc"
import { UI_KEY, uiReducer, uiInitialState, UIType } from "./ui"
import {
  USER_SYNCED_KEY,
  userSyncedReducer,
  userSyncedInitialState,
  UserSyncedType,
} from "./user-synced"

// 🏠 STATE



// State Value Type
export type StateType = {
  [UI_KEY]: UIType
  [MISC_KEY]: MiscType
  [USER_SYNCED_KEY]: UserSyncedType
}

// State Value (Initial)
export const initialState: StateType = {
  [UI_KEY]: uiInitialState,
  [MISC_KEY]: miscInitialState,
  [USER_SYNCED_KEY]: userSyncedInitialState,
}


// 🔻 Reducer
export const rootReducer = combineReducers({
  [UI_KEY]: uiReducer,
  [MISC_KEY]: miscReducer,
  [USER_SYNCED_KEY]: userSyncedReducer,
})
