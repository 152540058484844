// Intro:
// UI state we consider ephemeral and resettable between problems being attempted.
import {
  IN_PRODUCT_ONBOARDING_SHOW_KEY,
  InProductOnboardingShowType,
  inProductOnboardingShowInitialState,
} from "@redux/ducks/ui/in-product-onboarding-show"
import {
  MODAL_KEY,
  modalInitialState,
  ModalType,
  SET_MODAL,
  SetModalActionType,
} from "@redux/ducks/ui/modal"
import {
  OPTIONS_ARE_LOCKED_KEY,
  OptionsAreLockedType,
  optionsAreLockedInitialState,
} from "@redux/ducks/ui/options-are-locked"
import {
  PROBLEM_MULTIPLE_CHOICE_KEY,
  ProblemMultipleChoiceType,
  problemOptionsInitialState,
} from "@redux/ducks/ui/problem-multiple-choice"
import {
  SOLUTION_SHOW_KEY,
  SolutionShowType,
  solutionShowInitialState,
} from "@redux/ducks/ui/solution-show"

// 🏠 STATE

// State Key
export const UI_KEY = "ui"

// State Value Type
export type UIType = {
  [IN_PRODUCT_ONBOARDING_SHOW_KEY]: InProductOnboardingShowType
  [MODAL_KEY]: ModalType
  [OPTIONS_ARE_LOCKED_KEY]: OptionsAreLockedType
  [SOLUTION_SHOW_KEY]: SolutionShowType
  [PROBLEM_MULTIPLE_CHOICE_KEY]: ProblemMultipleChoiceType
}

// Payload Type. Payload is different from the state of the slice.
// (In most cases) everything should be optional so that a shallow merge is performed on state update.

interface UIPayloadType {
  [IN_PRODUCT_ONBOARDING_SHOW_KEY]?: InProductOnboardingShowType
  [MODAL_KEY]?: ModalType
  [OPTIONS_ARE_LOCKED_KEY]?: OptionsAreLockedType
  [SOLUTION_SHOW_KEY]?: SolutionShowType
  [PROBLEM_MULTIPLE_CHOICE_KEY]?: ProblemMultipleChoiceType
}

// State Value (Initial)
export const uiInitialState: UIType = {
  [IN_PRODUCT_ONBOARDING_SHOW_KEY]: inProductOnboardingShowInitialState,
  [MODAL_KEY]: modalInitialState,
  [OPTIONS_ARE_LOCKED_KEY]: optionsAreLockedInitialState,
  [SOLUTION_SHOW_KEY]: solutionShowInitialState,
  [PROBLEM_MULTIPLE_CHOICE_KEY]: problemOptionsInitialState,
}

// ⚡ ACTIONS

// 1. Set UI
// 1a. Action Constant
const SET_UI = "SET_UI"

// 1b. Action Type
interface SetUIActionType {
  type: typeof SET_UI
  payload: UIPayloadType
}

// 1c. Action creator
export const setUIActionCreator = (payload: UIPayloadType): SetUIActionType => {
  return {
    type: SET_UI,
    payload,
  }
}

// Action Types (All): All possible actions
export type UIActionType = SetUIActionType | SetModalActionType

// 🔻 Reducer

export const uiReducer = (
  state: UIType = uiInitialState,
  action: UIActionType
): UIType => {
  switch (action.type) {
    case SET_UI:
      return { ...state, ...action.payload }
    case SET_MODAL:
      return {
        ...state,
        [MODAL_KEY]: {
          ...state[MODAL_KEY],
          ...action.payload,
        },
      }
    default:
      return state
  }
}
