import Amplify from "aws-amplify"

import { getIsBrowser, getIsLocalHost } from "@helpers/misc"

import awsconfig from "../aws-exports"

export function updateAwsConfig() {
  let isLocalhost = false

  if (getIsBrowser()) {
    isLocalhost = getIsLocalHost()
  }

  // Assuming you have two redirect URIs, and the first is for localhost and second is for production
  const [productionRedirectSignIn, localRedirectSignIn] =
    awsconfig.oauth.redirectSignIn.split(",")

  const [productionRedirectSignOut, localRedirectSignOut] =
    awsconfig.oauth.redirectSignOut.split(",")

  const updatedAwsConfig = {
    ...awsconfig,
    oauth: {
      ...awsconfig.oauth,
      redirectSignIn: isLocalhost
        ? localRedirectSignIn
        : productionRedirectSignIn,
      redirectSignOut: isLocalhost
        ? localRedirectSignOut
        : productionRedirectSignOut,
    },
  }

  Amplify.configure(updatedAwsConfig)
}
