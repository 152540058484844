// Hair Style
// https://en.wikipedia.org/wiki/List_of_hairstyles
export const DRUID_HAIR_STYLE = "DruidHair"
export const DWARF_HAIR_STYLE = "DwarfHair"
export const BANDIT_HAIR_STYLE = "BanditHair"
export const SHOULDER_LENGTH_WAVY_HAIR_STYLE = "ShoulderLengthWavyHair"
export const FRINGE_STRAIGHT_LONG_HAIR_STYLE = "FringeStraightLongHair"
export const PARTED_FRONT_PONYTAIL_HAIR_STYLE = "PartedFrontPonytailHair"

// Unlocked means it's available during onboarding and generally
// A user will pay a cost to change their hair each time (a haircut). This is different to equips which we allow users to swap on/off without incurring a cost.
// Locked hair/equipment needs to be unlocked. This could be through buying premium or something else.

export const hairStyleList = {
  [DRUID_HAIR_STYLE]: {
    displayName: "Crew",
    cutCost: 3,
    unlocked: true,
  },
  [DWARF_HAIR_STYLE]: {
    displayName: "Slick Beard",
    cutCost: 4,
  },
  [BANDIT_HAIR_STYLE]: {
    displayName: "Discheveled",
    cutCost: 3,
    unlocked: true,
  },
  [SHOULDER_LENGTH_WAVY_HAIR_STYLE]: {
    displayName: "Short Wavy",
    cutCost: 3,
    unlocked: true,
  },
  [FRINGE_STRAIGHT_LONG_HAIR_STYLE]: {
    displayName: "Hime",
    cutCost: 4,
  },
  [PARTED_FRONT_PONYTAIL_HAIR_STYLE]: {
    displayName: "Asymmetric Braid",
    cutCost: 3,
    unlocked: true,
  },
}

export type HairStyleId =
  | typeof DRUID_HAIR_STYLE
  | typeof DWARF_HAIR_STYLE
  | typeof BANDIT_HAIR_STYLE
  | typeof SHOULDER_LENGTH_WAVY_HAIR_STYLE
  | typeof FRINGE_STRAIGHT_LONG_HAIR_STYLE
  | typeof PARTED_FRONT_PONYTAIL_HAIR_STYLE
