import { updateAwsConfig } from "@helpers/updateAwsConfig"

import wrapWithProvider from "./wrapWithProvider"

updateAwsConfig()
export const wrapRootElement = wrapWithProvider
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
