// 🏠 STATE
// State Key
export const USER_STATUS_KEY = "userStatus"

export const USER_STATUS_OK = "ok"
export const USER_STATUS_DANGER = "danger"

// State Value Type
export type UserStatusType = typeof USER_STATUS_OK | typeof USER_STATUS_DANGER

// State Value (Initial)
export const userStatusInitialState: UserStatusType = USER_STATUS_OK
