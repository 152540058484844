// 🏠 STATE

// State Key
export const PROBLEM_MULTIPLE_CHOICE_KEY = "problemMultipleChoice"

export type OptionsWereSelectedType =
  | {
      [key: string]: boolean
    }
  | undefined

// State Value Type
export interface ProblemMultipleChoiceType {
  lastSelectedKey?: string
  optionsWereSelected?: OptionsWereSelectedType
}

// State Value (Initial)
export const problemOptionsInitialState: ProblemMultipleChoiceType = {}
