import React from "react"
import { Provider } from "react-redux"

import { initialState } from "@redux/ducks"
import { configureStore } from "@redux/store"

// https://imedadel.me/how-to-use-redux-in-gatsby-with-react-hooks

type Props = {
  element: React.ReactNode
}

export default function wrapWithProvider(props: Props) {
  const store = configureStore(initialState)
  return <Provider store={store}>{props.element}</Provider>
}
