import { DateType } from "@components/types"

import { META_STATUS_NEUTRAL } from "@helpers/hooks/use-meta-status/constants"
import { MetaStatusType } from "@helpers/hooks/use-meta-status/types"

export { selectPayment } from "./selector"
export { getIsPaymentValid } from "./utils"

// 🏠 STATE

// State Key
export const PAYMENT_KEY = "payment"

// State Value Type
export type PaymentDataType = {
  hasPaid: boolean
  paidAmount: number
  fromDate: DateType
  toDate: DateType
  campaign?: string
}

export interface PaymentType extends MetaStatusType {
  data: PaymentDataType | undefined
}

// State Value (Initial).
export const paymentInitialState: PaymentType = {
  data: undefined,
  status: META_STATUS_NEUTRAL,
}
