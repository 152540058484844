// 🏠 STATE

// State Key
export const POTIONS_TANK_KEY = `potionsTank`

// State Value Type
export type PotionsTankType = number

// State Value (Initial)
export const potionsTankInitialState: PotionsTankType = 5
