import { JSXJSONType } from "@helpers/jsxJSONProcessor/types"

// 🏠 STATE

// State Key
export const MODAL_KEY = "modal"

// Modal ID constants
export const VIDEO_MODAL = "VIDEO_MODAL"
export const RESTING_VIDEO_MODAL = "RESTING_VIDEO_MODAL"
export const NOTES_MODAL = "NOTES_MODAL"
export const RESTING_NOTES_MODAL = "RESTING_NOTES_MODAL"
export const L1_TO_L2_MODAL = "L1_TO_L2_MODAL"
export const L2_TO_L3_MODAL = "L2_TO_L3_MODAL"

export type ModalIdType =
  | typeof VIDEO_MODAL
  | typeof NOTES_MODAL
  | typeof RESTING_NOTES_MODAL
  | typeof RESTING_VIDEO_MODAL
  | typeof L1_TO_L2_MODAL
  | typeof L2_TO_L3_MODAL

// State Value Type
export interface ModalType {
  modalId?: ModalIdType
  bodyContent?: JSXJSONType[]
}

// Allow bodyContent for a generic modal
export type ModalPayloadType = ModalType

// State Value (Initial)

export const modalInitialState: ModalType = {
  modalId: undefined,
  bodyContent: undefined,
}

// ⚡ ACTIONS

// 1. Update the modal
// 1a. Action Constant
export const SET_MODAL = "SET_MODAL"

// 1b. Action Type
export interface SetModalActionType {
  type: typeof SET_MODAL
  payload: ModalPayloadType
}

// 1c. Action creator
export const setModalActionCreator = (
  payload: ModalPayloadType
): SetModalActionType => ({
  type: SET_MODAL,
  payload,
})
